<template>
  <b-card>
    <b-row class="justify-content-start d-flex">
      <b-tabs content-class="mt-3">
        <b-tab
          title="Ativos"
          @click="(costsCategoryList = false), getAllCostsCategory()"
          active
        >
        </b-tab>
        <b-tab
          title="Inativos"
          @click="(costsCategoryList = true), getAllCostsCategory()"
        >
        </b-tab>
      </b-tabs>
    </b-row>

    <b-row class="justify-content-between d-flex">
      <b-col>
        <b-row class="ml-2">
          <b-form-group>
            <b-form-input
              type="text"
              v-model="search"
              placeholder="Buscar item"
            ></b-form-input>
          </b-form-group> </b-row
      ></b-col>
      <b-col class="d-flex justify-content-end">
        <b-row>
          <b-button
            class="mb-2 mr-2"
            variant="primary"
            @click="openModalDefault"
          >
            Novo item +
          </b-button>
        </b-row>
      </b-col>
    </b-row>

    <b-table
      responsive="sm"
      small
      :items="items && depreciableAssetsWithDepreciation"
      :fields="fields"
      class="p-1 w-100"
    >
      <template v-slot:cell(actions)="{ item }">
        <ActionsCostsCategory
          @openModal="openEditModal(item)"
          @openModaDelete="openDeleteModal(item)"
          :item="item"
        />
      </template>
      <template v-slot:cell(project_cost)="{ item }">
        {{ item.project_cost ? 'Sim' : 'Não' }}
      </template>

      <template v-slot:cell(office_cost)="{ item }">
        {{ item.office_cost ? 'Sim' : 'Não' }}
      </template>
    </b-table>
    <ModalCostsCategory
      :modalDepreciableAssets="modalDepreciableAssets"
      :modalDepreciableAssetsForm="modalDepreciableAssetsForm"
      :editable="editable"
      :itemUpdate="itemUpdate"
      @updateList="handleUpdateList"
      @renderList="getAllCostsCategory"
      @closeModal="closeModal()"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
  BRow,
  BButton,
  BTable,
  BFormGroup,
  BFormInput,
  BCol,
  // BIconTrash,
} from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';
import ActionsCostsCategory from './components/ActionsCostsCategory.vue';
import ModalCostsCategory from './components/ModalCostsCategory.vue';
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BTabs,
    BTab,
    BTable,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BFormInput,
    // BIconTrash,
    ModalCostsCategory,
    ActionsCostsCategory,
  },
  data: () => ({
    modalDepreciableAssets: false,
    modalDepreciableAssetsForm: false,
    editable: false,
    itemUpdate: null,
    fields: [
      {
        key: 'actions',
        label: 'Ações',
        sortable: false,
      },
      {
        key: 'expense_category_name',
        label: 'item',
        sortable: true,
      },
      {
        key: 'office_cost',
        label: 'Custo de Escritório',
        sortable: true,
      },
      {
        key: 'project_cost',
        label: 'Custo de Projetos',
        sortable: true,
      },
    ],
    items: [],
    costsCategoryList: false,
    search: '',
  }),

  created() {
    this.getAllCostsCategory();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
    depreciableAssetsFilteres() {
      let values = [];
      values = this.items.filter((item) => {
        return (
          item.expense_category_name
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
      return values;
    },
    depreciableAssetsWithDepreciation() {
      return this.depreciableAssetsFilteres.map((item) => {
        const acquisitionValue = item.acquisition_value;
        const residualValue = item.residual_value;
        const lifespanMonths = item.lifespan_months;

        // Calcular a depreciação mensal fixa em valor monetário
        const monthlyDepreciation =
          lifespanMonths > 0
            ? -(acquisitionValue - residualValue) / lifespanMonths
            : 0;

        return {
          ...item,
          depreciable: `R$ ${monthlyDepreciation.toFixed(2)}`, // Ajuste a precisão e o formato conforme necessário
        };
      });
    },
  },

  methods: {
    getAllCostsCategory() {
      this.items = [];
      if (!this.costsCategoryList) {
        this.$store
          .dispatch('getAllCostsCategory', {
            inactive: false,
            workspace_id: this.$store.getters.currentWorkspace.id,
          })
          .then((resp) => {
            if (resp) {
              this.items = resp;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.getInactivesDepreciableAssets();
      }
    },

    getInactivesDepreciableAssets() {
      this.$store
        .dispatch('getAllCostsCategory', {
          inactive: true,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          if (resp) {
            this.items = resp;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cleanFilter() {
      this.search = '';
    },
    openEditModal(item) {
      this.modalDepreciableAssets = true;
      this.editable = true;
      this.modalOpenDelete = false;
      this.modalDepreciableAssetsForm = true;
      this.itemUpdate = item.id;
    },
    openDeleteModal(item) {
      this.modalDepreciableAssets = true;
      this.modalDepreciableAssetsForm = false;
      this.modalOpenDelete = true;
      this.itemUpdate = item.id;
    },
    openModalDefault() {
      this.modalDepreciableAssets = true;
      this.modalDepreciableAssetsForm = true;
      this.modalOpenDelete = false;
      this.editable = false;
      // this.itemUpdate = item.id
    },
    closeModal() {
      this.modalDepreciableAssets = false;
      this.editable = false;
    },
    handleUpdateList() {
      this.getAllCostsCategory();
    },
  },
};
</script>
